import React from 'react';
import css from './CategoriesNav.module.css';
import { listingFields } from '../../../../config/configListing';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { getSliceSecondParam } from './utils';
import PetsOutlinedIcon from '@mui/icons-material/PetsOutlined';
import YardOutlinedIcon from '@mui/icons-material/YardOutlined';
import DirectionsCarFilledOutlinedIcon from '@mui/icons-material/DirectionsCarFilledOutlined';
import BrushOutlinedIcon from '@mui/icons-material/BrushOutlined';
import LiveTvOutlinedIcon from '@mui/icons-material/LiveTvOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { FormattedMessage } from 'react-intl';

const CategoriesNav = props => {
  const [hoveredCategory, setHoveredCategory] = useState(null);
  const [showAllCategories, setShowAllCategories] = useState(false);
  const [hoveredAllCategory, setHoveredAllCategory] = useState(null);

  const categoriesOptions = listingFields?.find(i => i?.key === 'category')?.enumOptions || [];

  const categoryOptionIcon = [
    { option: "accesorios_para_animales", icon: <PetsOutlinedIcon fontSize='small' className={css.icon} /> },
    { option: "accesorios_para_vehiculos", icon: <DirectionsCarFilledOutlinedIcon fontSize='small' className={css.icon} /> },
    { option: "antiguedades_y_arte", icon: <BrushOutlinedIcon fontSize='small' className={css.icon} /> },
    { option: "audio_tv_video", icon: <LiveTvOutlinedIcon fontSize='small' className={css.icon} /> },
    { option: "billetes_y_bonos", icon: <ConfirmationNumberOutlinedIcon fontSize='small' className={css.icon} /> },
    { option: "bricolaje_y_jardin", icon: <YardOutlinedIcon fontSize='small' className={css.icon} /> },
  ]

  // categoriesOptions.slice(0, getSliceSecondParam()).map(c => {
  //   console.log(categoryOptionIcon.find(i => i.option === c.option)?.option)
  // });


  const subcategoriesOptions =
    listingFields?.find(i => i?.key === 'subcategory')?.enumOptions || [];
  const filteredSubcategories = subcategoriesOptions.filter(s => s?.category === hoveredCategory);

  const filteredAllSubcategories = subcategoriesOptions.filter(s => s?.category === hoveredAllCategory);

  const history = useHistory();
  if (categoriesOptions?.length === 0) {
    return null;
  }
  const secondSliceParam = getSliceSecondParam();
  return (

    <div className={css.wrapper}>

      <div className={css.allWrapper}
        onMouseEnter={() => setShowAllCategories(true)}>
        <MenuIcon />
        <p>
          <FormattedMessage id="CategoriesNav.all" />
        </p>
      </div>

      {categoriesOptions.slice(0, secondSliceParam).map(c => {
        return (
          <div
            onMouseLeave={() => setHoveredCategory(null)}
            onMouseEnter={() => {
              setHoveredCategory(c?.option)
              setShowAllCategories(false)
            }}
            className={css.item}
            onClick={() => history.push(`/s?pub_category=${c?.option}`)}
          >
            {categoryOptionIcon.find(i => i.option === c.option)?.icon}
            {c?.label}
          </div>
        );
      })}

      {hoveredCategory && (
        <div
          className={css.subcategoriesWrapper}
          onMouseLeave={() => {
            setHoveredCategory(null);
          }}
          onMouseEnter={() => setHoveredCategory(hoveredCategory)}
        >
          {filteredSubcategories.map(s => {
            return (
              <div
                className={css.subcategoryItem}
                onClick={() => history.push(`/s?pub_subcategory=${s?.option}`)}
              >
                {s?.label}
              </div>
            );
          })}
        </div>
      )}

      {showAllCategories && (
        <div className={css.allCategoriesWrapper}>
          <div className='row cenV'>
            <h4>
              <FormattedMessage id="CategoriesNav.allCategories" />
            </h4>
            <CloseIcon className={css.closeIcon} onClick={() => setShowAllCategories(false)} />
          </div>
          {categoriesOptions.map(c => {
            return (
              <div className='row'>
                <div
                  onMouseLeave={() => setHoveredAllCategory(null)}
                  onMouseEnter={() => setHoveredAllCategory(c?.option)}
                  className={css.itemAll}
                  onClick={() => history.push(`/s?pub_category=${c?.option}`)}
                >
                  {/* {categoryOptionIcon.find(i => i.option === c.option)?.icon} */}

                  <div className={css.itemAllWrapper}>
                    <span className={css.label}>
                      {c?.label}
                    </span>

                    <ArrowForwardIosIcon />
                  </div>

                  {hoveredAllCategory && hoveredAllCategory === c.option && (

                    <div
                      className={css.allSubcategoriesWrapper}
                    // onMouseLeave={() => {
                    //   setHoveredAllCategory(null);
                    // }}
                    // onMouseEnter={() => setHoveredAllCategory(hoveredCategory)}
                    >
                      {filteredAllSubcategories.map(s => {
                        return (
                          <div
                            className={css.subcategoryItem}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              history.push(`/s?pub_subcategory=${s?.option}`)
                            }
                            }
                          >
                            {s?.label}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}


    </div>
  );
};

export default CategoriesNav;
