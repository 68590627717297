import React, { useState } from 'react';
import { bool, arrayOf, number, shape } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useConfiguration } from '../../context/configurationContext';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ensureCurrentUser, ensureUser } from '../../util/data';
import { withViewport } from '../../util/uiHelpers';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import { Page, LayoutSingleColumn, Button, ListingCard } from '../../components';

import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import FooterContainer from '../../containers/FooterContainer/FooterContainer';
import NotFoundPage from '../../containers/NotFoundPage/NotFoundPage';

import css from './GiveAwayPage.module.css';
import Slider from './components/Slider/Slider';
import BoxesSlider from './components/BoxesSlider/BoxesSlider';
import GiveawayModal from './components/GiveawayModal/GiveawayModal';
import CategoriesNav from './components/CategoriesNav/CategoriesNav';
import { useEffect } from 'react';
import { getListings } from './utils';
import HowItWorksSection from './components/HowItWorksSection/HowItWorksSection';
import HighlightSection from './components/HighlightSection/HighlightSection';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';

const GiveAwayPageComponent = props => {
  const [isOpen, setOpen] = useState(false);
  const [giveawayInfo, setGiveawayInfo] = useState(null);

  const [dealsOfTheDay, setDealsOfTheDay] = useState([]);
  const [giveaways, setGiveaways] = useState([]);
  const [auctions, setAuctions] = useState([]);

  const config = useConfiguration();

  useEffect(() => {
    //get giveaways
    getListings({ pub_listingVariant: 'giveaway' }, true)
      .then(resp => {
        setGiveaways(resp);
      })
      .catch(e => console.log(e));

    //get deals Of The Day
    getListings({ pub_listingVariant: 'listing' })
      .then(resp => {
        const today = new Date();

        const filteredListings = resp.filter(l => {
          const expiryDateProp = l.attributes.publicData.expiryDate;
          if (!expiryDateProp) {
            return true;
          }
          const expiryDate = new Date(expiryDateProp);
          return expiryDate >= today;
        })

        setDealsOfTheDay(filteredListings);
      })
      .catch(e => console.log(e));

    //get auctions
    getListings({ pub_listingVariant: 'auction' })
      .then(resp => {
        setAuctions(resp);
      })
      .catch(e => console.log(e));
  }, []);

  const {
    scrollingDisabled,
    currentUser,
    userShowError,
    user,
    intl,
    history,
    onUpdateProfile,
    ...rest
  } = props;

  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const profileUser = ensureUser(user);
  const isCurrentUser =
    ensuredCurrentUser.id && profileUser.id && ensuredCurrentUser.id.uuid === profileUser.id.uuid;
  const { bio, displayName } = profileUser?.attributes?.profile || {};

  const schemaTitleVars = { name: displayName, marketplaceName: config.marketplaceName };
  const schemaTitle = intl.formatMessage({ id: 'ProfilePage.schemaTitle' }, schemaTitleVars);

  if (userShowError && userShowError.status === 404) {
    return <NotFoundPage />;
  }

  const slides = giveaways.map(g => {

    const firstImageId = g?.images && g?.attributes.publicData.imagesOrder ? g?.attributes.publicData.imagesOrder[0].id : g?.images[0].id.uuid;
    // const imageUrl = firstImageId && g?.images.find(i => i.id.uuid === firstImageId)?.attributes?.variants?.default?.url;
    const currentGiveaways = currentUser?.attributes?.profile?.protectedData?.giveawayIds || [];
    const isSignedUp = currentUser?.id?.uuid && currentGiveaways.find(i => i === g?.id?.uuid);
    return (
      <div className={css.giveawaySlide}>
        <div className={css.giveawayInfo}>
          <h2 className={css.giveawaySlideTitle}>{g?.attributes?.title}!</h2>
          <p className={css.giveawayDescription}>{g?.attributes?.description}</p>
        </div>

        <Button
          className={css.continueButton}
          onClick={() => {
            setOpen(true);
            setGiveawayInfo({
              title: g?.attributes?.title,
              id: g?.id?.uuid,
            });
          }}
          disabled={isSignedUp}
        >
          {isSignedUp ? 'Inscrito' : 'Continuar'}
        </Button>
      </div>
    );
  });

  const deals = dealsOfTheDay.map(l => {
    if (!l) {
      return null;
    }

    const renderSizes = [
      '(max-width: 549px) 100vw',
      '(max-width: 767px) 50vw',
      `(max-width: 1439px) 26vw`,
      `(max-width: 1920px) 18vw`,
      `14vw`,
    ].join(', ');
    return (
      <ListingCard
        className={css.listingCard}
        key={l.id.uuid}
        listing={l}
        renderSizes={renderSizes}
        setActiveListing={() => { }}
        currentUser={currentUser}
      />
    );
  });

  const auctionListings = auctions.map(l => {
    if (!l) {
      return null;
    }

    const renderSizes = [
      '(max-width: 549px) 100vw',
      '(max-width: 767px) 50vw',
      `(max-width: 1439px) 26vw`,
      `(max-width: 1920px) 18vw`,
      `14vw`,
    ].join(', ');
    return (
      <ListingCard
        className={css.listingCard}
        key={l.id.uuid}
        listing={l}
        renderSizes={renderSizes}
        setActiveListing={() => { }}
        currentUser={currentUser}
      />
    );
  });

  const showDealsOfTheDay = !!dealsOfTheDay.find(d => d);
  const showAuctions = !!auctionListings.find(d => d);

  return (
    <Page
      scrollingDisabled={scrollingDisabled}
      title={schemaTitle}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'GiveAwayPage',
        name: schemaTitle,
      }}
    >
      <LayoutSingleColumn
        topbar={<TopbarContainer currentPage="GiveAwayPage" />}
        footer={<FooterContainer />}
      >
        <CategoriesNav />
        <div className={css.wrapper}>
          <GiveawayModal
            isOpen={isOpen}
            setOpen={setOpen}
            giveawayInfo={giveawayInfo}
            currentUser={currentUser}
            onUpdateProfile={onUpdateProfile}
          />
          <div className={css.sectionWrapper}>
            <div className={css.videoContaier}>
              <video className={css.video}
                autoPlay
                loop
                muted>
                <source src="/giveaway_background.mp4" type="video/mp4" />
              </video>

              {/* <Slider slides={slides} /> */}
            </div>

          </div>
          {showDealsOfTheDay && (
            <div className={css.sectionWrapper}>
              <h2 className={css.sectionTitle}>Ofertas del dia</h2>
              <BoxesSlider slides={deals} />
            </div>
          )}
          {showAuctions && (
            <div className={css.sectionWrapper}>
              <h2 className={css.sectionTitle}>El personal eligió SubastasOfertas del día</h2>
              <BoxesSlider slides={auctionListings} />
            </div>
          )}

          <div className={css.sectionWrapper}>
            <HowItWorksSection />
          </div>

          {/* <div className={css.sectionWrapper}>
            <HighlightSection />
          </div> */}
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

GiveAwayPageComponent.defaultProps = {
  currentUser: null,
  user: null,
  userShowError: null,
  queryListingsError: null,
  reviews: [],
  queryReviewsError: null,
};

GiveAwayPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUser: propTypes.currentUser,
  user: propTypes.user,
  userShowError: propTypes.error,
  queryListingsError: propTypes.error,
  listings: arrayOf(propTypes.listing).isRequired,
  reviews: arrayOf(propTypes.review),
  queryReviewsError: propTypes.error,

  // form withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateProfile: data => dispatch(updateProfile(data)),
});

const GiveAwayPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withViewport,
  injectIntl
)(GiveAwayPageComponent);

export default GiveAwayPage;
