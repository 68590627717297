import React from 'react';
import css from './HowItWorksSection.module.css';

const HowItWorksSection = () => {
  return (
    <div className={css.wrapper}>
      <h2 className={css.title}>Venda sus productos antiguos en minutos</h2>
      <p className={css.subtitle}>Convierte tus productos en efectivo</p>
      <div className={css.content}>
        <div className={css.infoSquare}>
          <p className={css.infoSquareTitle}>1. Enumere su producto</p>
          <p className={css.infoSquareDescription}>
          Cree una subasta o elija un precio y publique su listado de productos
          </p>
        </div>
        <div className={css.infoSquare}>
          <p className={css.infoSquareTitle}>2. Envíalos</p>
          <p className={css.infoSquareDescription}>
          Una vez que se vendan sus productos, empáquelos con cuidado y envíelos.
          </p>
        </div>
        <div className={css.infoSquare}>
          <p className={css.infoSquareTitle}>3. Recibe tu pago</p>
          <p className={css.infoSquareDescription}>
          Una vez que lleguen los artículos, elija un método de pago y reciba el pago.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HowItWorksSection;
