import React from 'react';
import css from './GiveawayModal.module.css';
import { Button, Modal } from '../../../../components';
import { Link } from 'react-router-dom';
import { useState } from 'react';

const GiveawayModal = props => {
  const { isOpen, setOpen, giveawayInfo, currentUser, onUpdateProfile } = props;
  const [email, setEmail] = useState(null);
  const isLoggedIn = currentUser?.id?.uuid;
  if (isLoggedIn) {
    const handleSignup = async () => {
      const currentGiveaways = currentUser?.attributes?.profile?.protectedData?.giveawayIds || [];
      await onUpdateProfile({
        protectedData: {
          giveawayIds: [...currentGiveaways, giveawayInfo?.id],
        },
      });
      setOpen(false);
      setEmail(null);
    };
    return (
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setOpen(false);
          setEmail(null);
        }}
        onManageDisableScrolling={() => {}}
      >
        <div className={css.content}>
          <h3 className={css.modalTitle}>{giveawayInfo?.title}</h3>
          <Button className={css.continueButton} type="button" onClick={handleSignup}>
            Sign up
          </Button>
        </div>
      </Modal>
    );
  }
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setOpen(false);
        setEmail(null);
      }}
      onManageDisableScrolling={() => {}}
    >
      <div className={css.content}>
        <h3 className={css.modalTitle}>{giveawayInfo?.title}</h3>
        <input
          className={css.textInput}
          placeholder="Enter email"
          type="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <Link
          className={css.continueButton}
          to={{
            pathname: '/signup',
            state: {
              email,
              giveawayInfo,
            },
          }}
        >
          Continuar
        </Link>
      </div>
    </Modal>
  );
};

export default GiveawayModal;
