import { createImageVariantConfig } from '../../util/sdkLoader';
import { util as sdkUtil } from '../../util/sdkLoader';

const sharetribeSdk = require('sharetribe-flex-sdk');
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

export const getListings = (extraFilters = {}, ignoreStock) => {
  const aspectWidth = 1;
  const aspectHeight = 1;
  const variantPrefix = 'listing-card';
  const aspectRatio = aspectHeight / aspectWidth;
  const minStockMaybe = ignoreStock ? {} : { minStock: 1 };
  return sdk.listings
    .query({
      ...minStockMaybe,
      ...extraFilters,
      include: ['author', 'images', 'author.profileImage', 'profileImage'],
      'fields.listing': ['title', 'geolocation', 'price', 'publicData', 'description'],
      'fields.user': [
        'profile.displayName',
        'profile.abbreviatedName',
        'profile.publicData',
        'profileImage',
        'profile.profileImage',
      ],
      'fields.image': [
        'variants.scaled-small',
        'variants.scaled-medium',
        `variants.${variantPrefix}`,
        `variants.${variantPrefix}-2x`,
        'variants.default',
      ],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
      // 'limit.images': 1,
    })
    .then(res => {
      const listings = res.data.data;
      const images =
        res.data.included &&
        res.data.included.filter(i => {
          return i.type === 'image';
        });
      const users =
        res.data.included &&
        res.data.included.filter(i => {
          return i.type === 'user';
        });

      if (users && users.length > 0) {
        users.forEach(u => {
          const imageId = u.relationships.profileImage.data?.id.uuid;

          const luckyImage = images.find(i => {
            return i.id.uuid === imageId;
          });

          u.profileImage = luckyImage;
        });
      }

      if (listings && listings.length > 0) {
        listings.forEach(l => {
          // const imageId = l.relationships.images.data[0]?.id.uuid;
          const authorId = l.relationships.author.data?.id.uuid;

          const listingImageRelationship = l.relationships.images.data;

          const imagesForListing = images.filter(i => {
            return listingImageRelationship.some(img => img.id.uuid === i.id.uuid);
          });

          const author = users.find(u => {
            return u?.id.uuid === authorId;
          });
          l.author = author;
          l.images = imagesForListing;
        });
      }

      return listings;
    })
    .catch(e => console.log(e));
};
