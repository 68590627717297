import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { isScrollingDisabled } from '../../ducks/ui.duck';

import {
    Page, LayoutSingleColumn,
    H3, ListingCard
} from '../../components';

import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import FooterContainer from '../../containers/FooterContainer/FooterContainer';
import css from './FavoritesPage.module.css';
import { loadData } from './FavoritesPage.duck';
import { useConfiguration } from '../../context/configurationContext';
// import ManageListingCard from './ManageListingCard/ManageListingCard';


export const FavoritesPageComponent = props => {
    const config = useConfiguration();
    const {
        favorites,
        queryFavoritesInProgress,
        queryFavoritesError,
        scrollingDisabled,
        intl,
        onRemoveFavorite,
        currentUser,
        onReloadData,
    } = props;

    console.log(favorites);
    const listingsAreLoaded = !queryFavoritesInProgress;

    const loadingResults = (
        <div className={css.messagePanel}>
            <H3 as="h2" className={css.heading}>
                <FormattedMessage id="FavoritesPage.loadingFavorites" />
            </H3>
        </div>
    );

    const queryError = (
        <div className={css.messagePanel}>
            <H3 as="h2" className={css.heading}>
                <FormattedMessage id="FavoritesPage.queryError" />
            </H3>
        </div>
    );

    const noResults =
        listingsAreLoaded && favorites.length === 0 ? (
            <div className={css.noResultsContainer}>
                <H3 as="h1" className={css.headingNoListings}>
                    <FormattedMessage id="FavoritesPage.noResults" />
                </H3>
            </div>
        ) : null;

    const heading =
        listingsAreLoaded && favorites.length > 0 ? (
            <H3 as="h1" className={css.heading}>
                <FormattedMessage
                    id="FavoritesPage.title"
                />
            </H3>
        ) : (
            noResults
        );

    const title = intl.formatMessage({ id: 'FavoritesPage.title' });

    const cardRenderSizes = () => {
        // Panel width relative to the viewport
        const panelMediumWidth = 50;
        const panelLargeWidth = 62.5;
        return [
            '(max-width: 549px) 100vw',
            '(max-width: 767px) 50vw',
            `(max-width: 1439px) 26vw`,
            `(max-width: 1920px) 18vw`,
            `14vw`,
        ].join(', ');
    };

    return (
        <Page title={title} scrollingDisabled={scrollingDisabled}>
            <LayoutSingleColumn
                topbar={
                    <>
                        <TopbarContainer currentPage="FavoritesPage" />
                    </>
                }
                footer={<FooterContainer />}
            >

                <div className={css.root} >
                    {heading}
                    {queryFavoritesInProgress ? loadingResults : null}
                    {queryFavoritesError ? queryError : null}

                    <div className={css.listingCards}>
                        {favorites?.map(l => (
                            <ListingCard
                                className={css.listingCard}
                                key={l.id.uuid}
                                listing={l}
                                renderSizes={cardRenderSizes()}
                                currentUser={currentUser}
                                setActiveListing={() => { }}
                                onRemoveFavorite={() => {
                                    onReloadData(config);
                                }}
                            />
                        ))}
                    </div>
                </div>
            </LayoutSingleColumn>
        </Page>
    );
}

FavoritesPageComponent.defaultProps = {
    favorites: [],
    queryFavoritesError: null,
    currentUser: null,
};

const { arrayOf, bool, func, object, shape, string } = PropTypes;

FavoritesPageComponent.propTypes = {

    currentUser: propTypes.currentUser,
    queryFavoritesInProgress: bool.isRequired,
    queryFavoritesError: propTypes.error,
    scrollingDisabled: bool.isRequired,

    // from injectIntl
    intl: intlShape.isRequired,
};

const mapStateToProps = state => {
    const {
        queryFavoritesInProgress,
        queryFavoritesError,
        favorites,
    } = state.FavoritesPage;

    const { currentUser } = state.user;

    return {
        queryFavoritesInProgress,
        queryFavoritesError,
        favorites,
        currentUser,
        scrollingDisabled: isScrollingDisabled(state),
    };
};

const mapDispatchToProps = dispatch => ({
    onReloadData: (config) => dispatch(loadData({}, {}, config)),
});

const FavoritesPage = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    injectIntl
)(FavoritesPageComponent);

export default FavoritesPage;